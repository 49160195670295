<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Directory Title Section -->
    <PageHeader class="pb-4" heading="Course Directory" fullscreen :sub-heading="'Last Refreshed: ' + store.data.lastRefresh">
      <Tooltip>
        The directory is refreshed every Monday @ 12AM EST. Course offerings and timetable data are refreshed daily @ 12AM EST
      </Tooltip>
    </PageHeader>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center" class="mt-0">
      <v-col>
        <!-- SEARCH & FILTER - Component for filters and querying directory -->
        <SearchAndFilter @update="directoryUpdate($event)" @clear="existingState = false" ref="searchBar"
                         :show-alphas="false" :reset-state="resetFilters" :search-query="searchQuery"/>
        <!-- DATA TABLE - Courses Section -->
        <v-row class="coursesRow mt-4" justify="center">
          <v-data-table class="elevation-0 coursesRow" :headers="courseHeaders" :items="directory.courses" item-key="code"
                        :loading="directory.loading" :server-items-length="directory.max" :expanded="directory.expanded"
                        :disable-sort="store.app.onMobile" :show-expand="!store.app.onMobile"
                        :footer-props="footerProps" :hide-default-header="store.app.onMobile" :options="directory.options"
                        @update:options="tableUpdate" @click:row="rowClick($event)"
                        no-data-text="No courses found. Try modifying your filters.">
            <!-- Templates for custom CSS on whatever columns -->
            <template v-slot:item.course="{ item }">
              <v-col :class="store.app.onMobile ? 'hover pl-0 py-0' : 'hover py-2 pl-0'" style="line-height: 1.2"
                     v-on="!store.app.onMobile ? { click: () => courseClick(item) } : {}">
                <h3 class="font-weight-medium courseTitle courseCode">{{ item.code }}
                  <v-icon v-if="item.taken" color="success" small class="ml-1">mdi-check-decagram</v-icon>
                  <v-icon v-else-if="item.saved && !item.taken" color="error" small class="ml-1">mdi-bookmark</v-icon>
                  <v-chip disabled color="transparent" small class="pa-0 ml-1"> • {{ item.campus }}</v-chip>
                </h3>
                <h4 class="font-weight-regular text-body-2 courseTitle text-left">{{ item.name }}</h4>
              </v-col>
            </template>
            <template v-slot:item.semesters="{ item }">
              <semester-chips :course="item.code" :semesters="item.semesters" icon/>
            </template>
            <template v-slot:item.rating="{ item }">
              <v-row justify="center" class="pr-4">
                <p v-if="item.numReviews <= 0" class="text--secondary mb-0">---</p>
                <h3 v-else class="font-weight-regular">{{ formatRating(item.rating) }}</h3>
              </v-row>
            </template>
            <template v-slot:item.workload="{ item }">
              <v-row justify="center" class="pr-4">
                <p v-if="item.numReviews <= 0" class="text--secondary mb-0">---</p>
                <h3 v-else class="font-weight-regular">{{ formatRating(item.workload) }}</h3>
              </v-row>
            </template>
            <template v-slot:item.recommend="{ item }">
              <v-row justify="center" class="pr-4">
                <p v-if="item.numReviews <= 0" class="text--secondary mb-0">---</p>
                <h3 v-else class="font-weight-regular">{{ formatRating(item.recommend) }}</h3>
              </v-row>
            </template>
            <template v-slot:item.numReviews="{ item }">
              <v-row justify="center" class="pr-4">
                <p v-if="item.numReviews <= 0" class="text--secondary mb-0">---</p>
                <h3 v-else class="font-weight-regular">{{ item.numReviews }}</h3>
              </v-row>
            </template>
            <template v-slot:item.semesters="{ item }">
              <semester-chips v-if="!store.app.onMobile" :course="item.code" :semesters="item.semesters" icon/>
              <v-row v-else class="mx-0 mobileStatRow">
                <semester-chips :course="item.code" :semesters="item.semesters" icon/>
                <v-spacer/>
                <chip :text="item.drop" class-name="justify-center" style="right: 45px; width: 60px" label drop-rate/>
              </v-row>
            </template>
            <template v-slot:item.drop="{ item }">
              <chip :text="item.drop" class-name="dropChip justify-center" style="width: 60px" label drop-rate/>
            </template>
            <template v-slot:item.actions="{ item }">
              <CourseToggle :already-taken="item.taken" :saved="item.saved" :course="item" icon="mdi-dots-horizontal"
                            @courseToggle="refreshData($event)"/>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :class="(!store.app.onMobile) ? 'courseInfo' : 'mobileInfo'" :colspan="headers.length">
                <InfoDisplay :course-info="item" :view-more="true" :show-tree="true" :dense="true"
                             :dynamic-codes="true" :show-semesters="true"/>
              </td>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import SearchAndFilter from '@/components/SearchAndFilter'
import InfoDisplay from '@/components/InfoDisplay'
import CourseToggle from '@/components/CourseToggle'
import Tooltip from '@/components/Tooltip'
import PageHeader from '@/components/PageHeader'
import SemesterChips from '@/components/SemesterChips'
import Chip from '@/components/Chip.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'Directory',
  components: { Chip, SearchAndFilter, InfoDisplay, CourseToggle, Tooltip, PageHeader, SemesterChips },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    initialLoad: true,
    existingState: false,
    prevToastID: null,
    manualTableUpdate: false,
    directory: {
      max: 0,
      options: { page: 1, itemsPerPage: 20, sortBy: [], sortDesc: [] },
      itemsPer: [20, 30, 40, 50],
      loading: true,
      sortOrder: { code: 1 },
      sortOverride: false,
      courses: [],
      expanded: []
    },
    stateVars: ['directory', 'searchQuery', 'retainQuery', 'filters'],
    filters: [],
    resetFilters: false,
    searchQuery: null,
    retainQuery: true,
    mobileHeaders: [1, 6],
    tableHeaders: [
      { text: '', value: 'data-table-expand', align: 'start pr-1' },
      { text: 'Course', sortable: false, value: 'course', align: 'start' },
      { text: 'Overall', value: 'rating', align: 'center d-none d-md-table-cell' },
      { text: 'Workload', value: 'workload', align: 'center d-none d-md-table-cell' },
      { text: 'Recommend', value: 'recommend', align: 'center d-none d-md-table-cell' },
      { text: 'Reviews', value: 'numReviews', align: 'center d-none d-xl-table-cell' },
      { text: 'Current Offerings', sortable: false, value: 'semesters', align: 'start' },
      { text: 'Drop Rate', value: 'drop', align: 'start pr-0' }
    ]
  }),
  beforeMount () {
    if (this.store.data.directoryState.directory) {
      this.existingState = true
      this.loadState()
    } else {
      this.resetFilters = true
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.searchBar.removeWatcher()
    if (['/courses', '/tree'].includes(to.path) && Object.keys(to.query).length) {
      this.saveState()
    } else {
      this.store.data.resetSelectedFilters()
      this.store.data.resetDirectoryState()
      this.store.data.resetDirectoryRetainQuery()
    }
    next()
  },
  computed: {
    courseHeaders () {
      if (!this.store.app.onMobile) {
        if (this.store.user.userInfo) {
          return this.tableHeaders.slice(0, 11)
        } else {
          return [].concat(this.tableHeaders[0], this.tableHeaders.slice(1, 9))
        }
      } else {
        return this.tableHeaders.filter((element, index) => this.mobileHeaders.includes(index))
      }
    },
    footerProps () {
      return {
        itemsPerPageOptions: this.directory.itemsPer,
        showFirstLastPage: !this.store.app.onMobile,
        itemsPerPageText: !this.store.app.onMobile ? 'Courses per page' : ''
      }
    }
  },
  methods: {
    saveState () {
      this.stateVars.forEach((element) => {
        this.store.data.setDirectoryStateBykey(element, this[element])
      })
    },
    loadState () {
      this.stateVars.forEach((element) => {
        this[element] = this.store.data.directoryState[element]
      })
      if (!this.retainQuery) this.searchQuery = null
    },
    tableUpdate (e) {
      this.directory.options = e
      if (!this.initialLoad && !this.manualTableUpdate) {
        if (e.sortBy.length > 0) {
          this.directory.sortOverride = true
          const tempObj = {}
          tempObj[e.sortBy[0]] = (e.sortDesc[0]) ? -1 : 1
          this.directory.sortOrder = tempObj
        } else {
          this.directory.sortOverride = false
          this.directory.sortOrder = this.filteringByCampus() ? { id: 1 } : { code: 1 }
        }
        this.$scrollTo('#topOfPage', {
          duration: 200,
          onDone: () => { this.getCourses(this.searchQuery, this.filters) }
        })
      } else {
        this.initialLoad = false
        this.manualTableUpdate = false
      }
    },
    directoryUpdate (params) {
      if (params[0]) {
        const courseIDX = this.store.data.courseList.findIndex((element) => { return element === params[0] })
        if (courseIDX > -1) {
          const courseCode = this.store.data.courseList[courseIDX].slice(0, 8)
          // GTAG
          this.$gtag.event('directory_' + courseCode, { value: 1 })
          this.retainQuery = false
          this.$router.push({ path: '/courses', query: { c: courseCode } })
        } else {
          // GTAG
          this.$gtag.event('directory_search', { value: 1 })
          this.retainQuery = true
          this.getCourses(params[0], params[1])
        }
      } else {
        if (!this.existingState) {
          this.manualTableUpdate = true
          this.directory.options.page = 1
        }
        this.getCourses(params[0], params[1])
      }
    },
    async getCourses (query, filters) {
      // Save filters and query and prepare to load new data
      this.directory.loading = true
      this.searchQuery = query
      this.filters = filters

      // Reset certain directory states
      this.directory.expanded = []
      // Form the Query
      const q = {
        query: 'query getCourses ($query: String, $skip: Float!, $limit: Float!, $sort: JSON!, $filters: [JSON]) { ' +
          'getCourses (query: $query, skip: $skip, limit: $limit, sort: $sort, filters: $filters) { info, courses } }',
        variables: {
          query: query ? query.trim() : null,
          skip: ((this.directory.options.page - 1) * this.directory.options.itemsPerPage),
          limit: this.directory.options.itemsPerPage,
          sort: (this.filteringByCampus() && !this.directory.sortOverride) ? { id: 1 } : this.directory.sortOrder,
          filters: filters
        },
        operationName: 'getCourses'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.directory.max = graphQlRes.data.getCourses.info.courseCount
            this.directory.courses = graphQlRes.data.getCourses.courses
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.directory.loading = false
          this.existingState = false
          this.manualTableUpdate = false
          if (!this.directory.sortOverride) this.directory.sortOrder = { code: 1 }
        })
        .catch(() => this.showSnack(false, false, false))
    },
    refreshData (update) {
      if (update) this.getCourses(this.searchQuery, this.filters)
    },
    rowClick (course) {
      if (this.store.app.onMobile) this.courseClick(course)
    },
    filteringByCampus () {
      return this.filters.findIndex(filter => filter.value === 'campus') > -1
    },
    courseClick (course) {
      const courseIDX = this.directory.expanded.findIndex((element) => { return element.code === course.code })
      if (courseIDX > -1) {
        this.directory.expanded.splice(courseIDX, 1)
      } else {
        this.directory.expanded.push(course)
      }
    },
    formatRating (value) {
      const formatted = value % 1 !== 0 ? value.toFixed(1) : value
      if (String(formatted).endsWith('.0')) {
        return parseInt(formatted)
      } else {
        return formatted
      }
    },
    showSnack (message, colour, icon) {
      if (this.prevToastID) this.$toast.dismiss(this.prevToastID)

      if (!(message || colour || icon)) {
        this.directory.loading = false
        this.prevToastID = this.$toast.error('An error occurred when contacting the server. Please try again later.')
      } else {
        this.prevToastID = this.$toast(message, { type: colour })
      }
    }
  }
}
</script>

<style scoped>
  >>> .v-data-table__expanded.v-data-table__expanded__content {
    box-shadow: none !important;
  }
  .v-text-field__details {
    padding-top: 0 !important;
    height: 0 !important;
  }
  >>>.v-toolbar__content {
    padding-right: 0;
  }
  .v-application a {
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    color: #003C85 !important;
  }
  .coursesRow {
    min-width: 100%;
  }
  .mobileStatRow {
    position: absolute;
    min-width: 100%;
    align-items: center;
    margin-top: -18px;
  }
  .courseInfo {
    padding: 40px 75px !important;
  }
  .mobileInfo {
    padding: 40px 20px !important;
    overflow-x: clip;
  }
  .dropChip {
    width: 60px;
    font-weight: bold;
    justify-content: center;
  }

</style>
