var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"contentContainer"},[_c('PageHeader',{staticClass:"pb-4",attrs:{"heading":"Course Directory","fullscreen":"","sub-heading":'Last Refreshed: ' + _vm.store.data.lastRefresh}},[_c('Tooltip',[_vm._v(" The directory is refreshed every Monday @ 12AM EST. Course offerings and timetable data are refreshed daily @ 12AM EST ")])],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',[_c('SearchAndFilter',{ref:"searchBar",attrs:{"show-alphas":false,"reset-state":_vm.resetFilters,"search-query":_vm.searchQuery},on:{"update":function($event){return _vm.directoryUpdate($event)},"clear":function($event){_vm.existingState = false}}}),_c('v-row',{staticClass:"coursesRow mt-4",attrs:{"justify":"center"}},[_c('v-data-table',{staticClass:"elevation-0 coursesRow",attrs:{"headers":_vm.courseHeaders,"items":_vm.directory.courses,"item-key":"code","loading":_vm.directory.loading,"server-items-length":_vm.directory.max,"expanded":_vm.directory.expanded,"disable-sort":_vm.store.app.onMobile,"show-expand":!_vm.store.app.onMobile,"footer-props":_vm.footerProps,"hide-default-header":_vm.store.app.onMobile,"options":_vm.directory.options,"no-data-text":"No courses found. Try modifying your filters."},on:{"update:options":_vm.tableUpdate,"click:row":function($event){return _vm.rowClick($event)}},scopedSlots:_vm._u([{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('v-col',_vm._g({class:_vm.store.app.onMobile ? 'hover pl-0 py-0' : 'hover py-2 pl-0',staticStyle:{"line-height":"1.2"}},!_vm.store.app.onMobile ? { click: function () { return _vm.courseClick(item); } } : {}),[_c('h3',{staticClass:"font-weight-medium courseTitle courseCode"},[_vm._v(_vm._s(item.code)+" "),(item.taken)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"success","small":""}},[_vm._v("mdi-check-decagram")]):(item.saved && !item.taken)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"error","small":""}},[_vm._v("mdi-bookmark")]):_vm._e(),_c('v-chip',{staticClass:"pa-0 ml-1",attrs:{"disabled":"","color":"transparent","small":""}},[_vm._v(" • "+_vm._s(item.campus))])],1),_c('h4',{staticClass:"font-weight-regular text-body-2 courseTitle text-left"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.semesters",fn:function(ref){
var item = ref.item;
return [(!_vm.store.app.onMobile)?_c('semester-chips',{attrs:{"course":item.code,"semesters":item.semesters,"icon":""}}):_c('v-row',{staticClass:"mx-0 mobileStatRow"},[_c('semester-chips',{attrs:{"course":item.code,"semesters":item.semesters,"icon":""}}),_c('v-spacer'),_c('chip',{staticStyle:{"right":"45px","width":"60px"},attrs:{"text":item.drop,"class-name":"justify-center","label":"","drop-rate":""}})],1)]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pr-4",attrs:{"justify":"center"}},[(item.numReviews <= 0)?_c('p',{staticClass:"text--secondary mb-0"},[_vm._v("---")]):_c('h3',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.formatRating(item.rating)))])])]}},{key:"item.workload",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pr-4",attrs:{"justify":"center"}},[(item.numReviews <= 0)?_c('p',{staticClass:"text--secondary mb-0"},[_vm._v("---")]):_c('h3',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.formatRating(item.workload)))])])]}},{key:"item.recommend",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pr-4",attrs:{"justify":"center"}},[(item.numReviews <= 0)?_c('p',{staticClass:"text--secondary mb-0"},[_vm._v("---")]):_c('h3',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.formatRating(item.recommend)))])])]}},{key:"item.numReviews",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pr-4",attrs:{"justify":"center"}},[(item.numReviews <= 0)?_c('p',{staticClass:"text--secondary mb-0"},[_vm._v("---")]):_c('h3',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.numReviews))])])]}},{key:"item.drop",fn:function(ref){
var item = ref.item;
return [_c('chip',{staticStyle:{"width":"60px"},attrs:{"text":item.drop,"class-name":"dropChip justify-center","label":"","drop-rate":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('CourseToggle',{attrs:{"already-taken":item.taken,"saved":item.saved,"course":item,"icon":"mdi-dots-horizontal"},on:{"courseToggle":function($event){return _vm.refreshData($event)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{class:(!_vm.store.app.onMobile) ? 'courseInfo' : 'mobileInfo',attrs:{"colspan":headers.length}},[_c('InfoDisplay',{attrs:{"course-info":item,"view-more":true,"show-tree":true,"dense":true,"dynamic-codes":true,"show-semesters":true}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }