<template>
  <v-container class="pa-0 ma-0" style="min-width: 100%">
    <v-row class="align-center" :justify="justifyTo">
      <!-- AUTOCOMPLETE SEARCH - Search Bar Section -->
      <v-col :class="!searchOnly && `pa-0 mr-0 mr-sm-6 my-2 col-lg-${searchCols} col-md-${searchCols} col-sm-12 align-center`" cols="12">
        <v-combobox clearable :hide-no-data="!dynamicList" :items="courseList" solo-inverted background-color="border"
                    flat prepend-inner-icon="mdi-magnify" height="35" label="Course or Keyword ( Press ' / ' )"
                    hide-details ref="searchBar" v-model="query" @input="courseSelected" append-icon=""
                    :cache-items="!dynamicList" open-on-clear @click:clear="query = null" item-text="course"
                    item-value="course" :loading="dynamicList && dynamicCourseList.length > 0 && updatingList">
          <template v-slot:no-data>
            <v-list-item-content class="px-4 py-3">
              {{ 1 > dynamicCourseList.length && dynamicList ? 'Searching for courses ...' : 'No courses available' }}
            </v-list-item-content>
          </template>
          <template v-if="dynamicList && courseList.length && courseList[0].semesters" v-slot:item="data">
            <v-list-item-content>
              <v-row class="pl-3 pr-1 align-center">
<!--                <p class="mb-0 mr-12" v-html="data.parent.genFilteredText(data.item.course)"/>-->
                <p class="mb-0 mr-12">{{ data.item.course }}</p>
                <v-spacer/>
                <SemesterChips :icon="true" :semesters="data.item.semesters"/>
              </v-row>
            </v-list-item-content>
          </template>
        </v-combobox>
      </v-col>
      <!-- FEATURED FILTERS - Permanent filters (Most popular) -->
      <v-col v-for="(active, idx) in featured" :key="idx" class="flex-grow-0 pa-0 my-2 mr-4">
        <v-menu v-if="!onMobile" bottom max-height="500px" offset-y nudge-bottom="10"
                :close-on-content-click="!filters.standard[active].allowMultiple">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="border" class="text-none pr-1 text--secondary text--darken-1" height="43"
                   depressed v-bind="attrs" v-on="on">{{ filters.standard[active].filter }}
              <v-spacer/>
              <v-icon color="#ADADAD" class="pl-2">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="filters.standard[active].selected" :multiple="filters.standard[active].allowMultiple">
              <template v-for="(option, idx) in filters.standard[active].options">
                <v-list-item :key="idx" :value="option" active-class="accent--text">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="accent"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="pr-2" v-text="option"/>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
      <v-divider v-if="onDesktop && !searchOnly && featured.length" vertical class="my-3 ml-1 mr-5"/>
      <v-spacer v-if="!onDesktop && !searchOnly"/>
      <v-btn v-if="!searchOnly" outlined class="text-none px-4 accent--text font-weight-medium" depressed height="43" data-cy="show_all_filters" @click="filterPanel = true">
        <v-badge :content="filterCount" :value="filterCount" color="warning" class="mr-3" overlap>
          <v-icon color="accent" class="mr-1">mdi-filter-variant</v-icon>
        </v-badge>All Filters
      </v-btn>
      <slot name="append"></slot>
    </v-row>
    <!-- ALPHA OPTIONS - Chip groups for alphabetic filtering -->
    <v-row v-if="!onMobile && showAlphas && !searchOnly" class="mt-7 mb-4" justify="center">
      <v-col cols="12" class="col-lg-8 col-md-9 col-sm-10 px-0">
        <v-chip-group mandatory show-arrows active-class="accent--text font-weight-medium"
                      v-model="filters.currentAlpha">
          <v-chip v-for="(alpha, idx) in filters.alphaKeys" :key="idx" label color="transparent"
                  class="text-body-1 px-5">{{ alpha }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <!-- FILTER CHIPS - Chip groups for all active filters -->
    <v-row v-if="currentFilters.length && !onMobile && !searchOnly && showFilterChips" class="mt-4">
      <v-chip-group column>
        <v-chip v-for="(filter) in currentFilters" :key="filter.value" color="accent" class="mr-3">
          {{ filter.filter }}: <strong class="ml-1 mr-2">{{ filter.value }}</strong>
          <v-icon size="16" @click="removeFilter(filter)">mdi-close</v-icon>
        </v-chip>
        <v-chip color="transparent" class="mr-0" disabled><v-divider vertical/></v-chip>
        <v-chip color="background" class="accent--text" @click="clearFilters">Clear All</v-chip>
      </v-chip-group>
    </v-row>
    <!-- ALL FILTERS - Side Nav panel with all filters -->
    <v-navigation-drawer v-model="filterPanel" temporary fixed right color="component" hide-overlay width="465px"
                         style="z-index: 100; overflow: hidden;">
      <span slot="prepend">
        <v-row class="my-7 px-8 align-center">
          <v-icon color="accent" class="mr-3">mdi-filter-variant</v-icon>
          <v-toolbar-title class="mb-0">Course Filters</v-toolbar-title>
          <p v-show="currentFilters.length > 0" @click="clearFilters"
             class="accent--text font-weight-medium hover align-self-end mb-0 pl-5">Clear All</p>
           <v-spacer/>
          <v-icon class="mr-1" data-cy="close_filters" @click="filterPanel = false">mdi-close</v-icon>
        </v-row>
        <v-divider class="mt-2"/>
      </span>
      <v-expansion-panels tile accordion multiple style="padding-left: 1px; padding-bottom: 76px;" :value="defaultExpanded">
        <v-expansion-panel v-for="(filter, idx) in filters.standard" :key="idx" class="pt-2 pb-1 component">
          <v-expansion-panel-header class="py-1 py-md-4">
            <v-badge :content="filter.selected.length" :value="filter.selected.length > 0 && filter.allowMultiple"
                     color="accent" overlap :offset-y="22" :offset-x="1">
              <p class="text-body-1 font-weight-medium mb-0 mr-3">{{ filter.filter }}</p>
              <small v-if="filter.note" class="text--secondary font-weight-medium>">{{ filter.note }}</small>
            </v-badge>
            <v-spacer/>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-chip-group active-class="accent--text font-weight-medium" data-cy="filter_section" column
                          v-model="filter.selected" :multiple="filter.allowMultiple">
              <v-chip v-for="(option, idx) in filter.options" :key="idx" label outlined class="text-body-1 px-4"
                      :value="option" filter>{{ option }}
              </v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="fixedBottom py-4" justify="center">
        <v-btn class="flex-fill mx-3" color="accent" large depressed @click="filterPanel = !filterPanel">
          <p class="mb-0 white--text text-body-1 font-weight-medium">View Courses</p>
        </v-btn>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import SemesterChips from '@/components/SemesterChips'
import { sortSemesters } from '@/utils/shared/helpers'
import { mapState } from 'pinia'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'SearchAndFilter',
  components: { SemesterChips },
  props: {
    /** Size of the searchbar */
    searchCols: { type: String, default: '4' },

    /** If only the searchbar should be visible. */
    searchOnly: { type: Boolean, default: false },
    searchQuery: { type: String, default: null },
    showFilterChips: { type: Boolean, default: true },
    showAlphas: { type: Boolean, default: true },
    dynamicList: { type: Boolean, default: false },
    featured: { type: Array, default: () => { return [1, 4, 2] } },
    showCourses: { type: Boolean, default: false },
    resetState: { type: Boolean, default: false },
    justify: {}
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    return {
      justifyTo: this.justify || 'start',
      updatingList: false,
      previousState: false,
      campusFilter: false,
      filterPanel: false,
      query: null,
      currentFilters: [],
      dynamicCourseList: [],
      filters: {
        alphaKeys: [],
        currentAlpha: null,
        standard: [
          {
            filter: 'Campus',
            note: '',
            value: 'campus',
            allowMultiple: true,
            options: ['UTSC', 'UTSG', 'UTM'],
            selected: []
          },
          {
            filter: 'Field of Study',
            note: '',
            value: 'section',
            allowMultiple: true,
            options: [],
            selected: []
          },
          {
            filter: 'Current Offerings',
            note: '',
            value: 'semesters',
            allowMultiple: true,
            options: [],
            selected: []
          },
          {
            filter: 'Course Level',
            note: '',
            value: 'level',
            allowMultiple: true,
            options: ['A / 1', 'B / 2', 'C / 3', 'D / 4', '5+'],
            selected: []
          },
          {
            filter: 'Breadth REQ',
            note: 'Applicable only for UTSC & UTSG',
            value: 'breadth',
            allowMultiple: true,
            options: [],
            selected: []
          },
          {
            filter: 'Distribution REQ',
            note: 'Applicable only for UTSG & UTM',
            value: 'distribution',
            allowMultiple: true,
            options: [],
            selected: []
          },
          {
            filter: 'Bird Count',
            note: '',
            value: 'bird',
            allowMultiple: false,
            options: ['At least 1 Bird', 'At least 10 Birds', 'At least 25 Birds', 'At least 50 Birds'],
            selected: []
          },
          {
            filter: 'Drop Rate',
            note: '',
            value: 'drop',
            allowMultiple: false,
            options: ['10% or lower', '20% or lower', '30% or lower', '40% or lower', '50% or lower'],
            selected: []
          },
          {
            filter: 'Number of Reviews',
            note: '',
            value: 'numReviews',
            allowMultiple: false,
            options: ['At least 1 Review', 'At least 10 Reviews', 'At least 25 Reviews', 'At least 50 Reviews'],
            selected: []
          },
          {
            filter: 'Overall Rating',
            note: '',
            value: 'rating',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          },
          {
            filter: 'Workload',
            note: '',
            value: 'workload',
            allowMultiple: false,
            options: ['1.0 or lower', '2.0 or lower', '3.0 or lower', '4.0 or lower', '5.0 or lower'],
            selected: []
          },
          {
            filter: 'Recommended',
            note: '',
            value: 'recommend',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          },
          {
            filter: 'Understanding',
            note: 'Provided a deeper understanding of the subject',
            value: 'understanding',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          },
          {
            filter: 'Evaluations',
            note: 'Evaluations improved course understanding',
            value: 'evaluations',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          },
          {
            filter: 'Environment',
            note: 'The professor created a good learning environment',
            value: 'environment',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          },
          {
            filter: 'Application',
            note: 'Evaluations allowed me to demonstrate my knowledge',
            value: 'application',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          },
          {
            filter: 'Stimulating',
            note: 'The course was intellectually stimulating',
            value: 'stimulating',
            allowMultiple: false,
            options: ['+1.0', '+2.0', '+3.0', '+4.0'],
            selected: []
          }
        ]
      },
      unwatch: null
    }
  },
  mounted () {
    document.addEventListener('keydown', this.onDocumentKeydown)
    // Check for home campus
    if (this.resetState) this.checkHomeCampus()

    // Load course list
    if (this.dynamicList && !this.store.data.filterState.filters) {
      this.getDirectoryInfo()
    } else {
      // Check for search query
      if (this.store.data.directoryState.retainQuery) {
        this.query = this.store.data.directoryState.searchQuery
      } else {
        this.store.data.setDirectoryStateBykey('searchQuery', null)
      }
      // Check for previous filter state
      if (this.store.data.filterState.filters) {
        this.filters = this.store.data.filterState.filters
        if (this.resetState) this.checkHomeCampus()
        this.updateCurrentFilters()
        this.getCourses()
      } else {
        this.getDirectoryInfo()
      }
    }
    // Create filter watcher
    this.unwatch = this.$watch('filters', filtersUpdated, { deep: true })
    function filtersUpdated () {
      this.updateCurrentFilters()
      this.getCourses()
    }
  },
  updated () { this.$emit('updated') },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onDocumentKeydown)
    this.store.data.setCurrentFilterState(this.currentFilters)
  },
  computed: {
    ...mapState(useAllStores, {
      onMobile: store => store.app.onMobile,
      onDesktop: store => store.app.onDesktop,
      user: store => store.user.userInfo
    }),
    courseList () {
      return this.dynamicList ? this.dynamicCourseList : this.store.data.courseList
    },
    defaultExpanded () {
      if (this.onMobile) {
        return []
      } else {
        return this.filters.standard.map((filter, index) => { if (filter.options.length <= 10) { return index } })
      }
    },
    filterCount () {
      return [].concat(...this.filters.standard).filter(filter => filter.selected && filter.selected.length > 0).length
    }
  },
  watch: {
    justify: function (newVal) { this.justifyTo = newVal || 'start' },
    filterPanel: function (newValue) { this.$emit('toggle', newValue) },
    searchQuery: function (newValue) {
      if (newValue !== this.query) {
        this.query = newValue
        this.getCourses()
      }
    },
    showCourses: function (newValue) {
      if (newValue) {
        this.$refs.searchBar.focus()
        this.$refs.searchBar.activateMenu()
      }
    }
  },
  methods: {
    removeWatcher () { this.unwatch() },
    checkHomeCampus () {
      if (this.user?.defaultCampus && this.filters.standard[0].selected.indexOf(this.user.defaultCampus) < 0) {
        this.filters.standard[0].selected.push(this.user.defaultCampus)
      }
    },
    updateCurrentFilters () {
      this.currentFilters = []
      this.filters.standard.forEach((filter) => {
        if (filter.selected == null) {
          filter.selected = ''
        } else if (filter.selected.length > 0) {
          if (filter.selected.constructor === Array) {
            filter.selected.forEach((value) => { this.currentFilters.push({ filter: filter.filter, value: value }) })
          } else {
            this.currentFilters.push({ filter: filter.filter, value: filter.selected })
          }
        }
      })
    },
    async getDirectoryInfo () {
      const q = { query: 'query getDirectoryInfo { getDirectoryInfo { info } }', operationName: 'getDirectoryInfo' }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.store.data.setLastRefresh(graphQlRes.data.getDirectoryInfo.info[0])

            this.filters.alphaKeys = [].concat(['All'], graphQlRes.data.getDirectoryInfo.info[1].alphas.sort())
            this.filters.standard[1].options = graphQlRes.data.getDirectoryInfo.info[1].sections.sort()
            this.filters.standard[4].options = graphQlRes.data.getDirectoryInfo.info[1].breadths.sort()
            this.filters.standard[5].options = graphQlRes.data.getDirectoryInfo.info[1].distributions.sort()
            this.filters.standard[2].options = graphQlRes.data.getDirectoryInfo.info[1].semesters
            this.filters.standard[2].options.sort(sortSemesters).reverse()
            // Store the filters for next time
            this.store.data.setFilters(this.filters)
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.showSnack(false, false, false))
    },
    getCourses () {
      const courseFilters = []
      this.filters.standard.forEach((filter) => {
        if (filter.selected.length > 0) {
          courseFilters.push({ value: filter.value, selected: filter.selected })
          this.$gtag.event('filter_' + filter.value, { value: 1 })
        }
      })
      courseFilters.push({
        value: 'alphaKey',
        selected: (this.filters.currentAlpha && this.filters.currentAlpha > 0) ? this.filters.alphaKeys[this.filters.currentAlpha] : []
      })
      if (this.dynamicList) {
        this.updateCourseList(courseFilters)
      } else {
        this.$emit('update', [this.query, courseFilters])
      }
    },
    async updateCourseList (filters) {
      this.updatingList = true
      const q = {
        query: `query getCourseList ($filters: [JSON], $current: Boolean!) {
                  getCourseList (filters: $filters, current: $current) {
                    code ${this.onMobile ? '' : ', name'}, semesters } }`,
        variables: { filters: filters, current: true },
        operationName: 'getCourseList'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.dynamicCourseList = graphQlRes.data.getCourseList.map(course => {
              return { course: (this.onMobile ? course.code : course.code + ': ' + course.name), semesters: course.semesters }
            })
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.updatingList = false
        })
        .catch(() => this.showSnack(false, false, false))
    },
    courseSelected (course) {
      if (this.dynamicList && course?.course) {
        this.$nextTick(() => { this.query = null })
        this.$emit('selected', course.course)
        this.$refs.searchBar.blur()
      } else {
        this.query = course
        this.getCourses()
      }
    },
    clearFilters () {
      if (this.onMobile) this.filterPanel = false
      this.filters.currentAlpha = null
      this.currentFilters = []
      this.filters.standard.forEach((filter) => { filter.selected = [] })
      this.$gtag.event('reset_filters', { value: 1 })
      this.$emit('clear')
    },
    removeFilter (filter) {
      const filterIDX = this.filters.standard.findIndex(x => x.filter === filter.filter)
      const valueIDX = this.filters.standard[filterIDX].selected.indexOf(filter.value)

      if (this.filters.standard[filterIDX].allowMultiple) {
        this.filters.standard[filterIDX].selected.splice(valueIDX, 1)
      } else {
        this.filters.standard[filterIDX].selected = []
      }
      this.$emit('clear')
    },
    onDocumentKeydown (e) {
      if (e.key === '/' && e.target !== this.$refs.searchBar.$refs.input) {
        e.preventDefault()
        this.$refs.searchBar.focus()
      } else if (e.key === 'Enter' && e.target === this.$refs.searchBar.$refs.input) {
        this.$refs.searchBar.blur()
      }
    },
    showSnack (message, colour, icon) {
      if (this.prevToastID) this.$toast.dismiss(this.prevToastID)

      if (!(message || colour || icon)) {
        this.prevToastID = this.$toast.error('An error occurred when contacting the server. Please try again later.')
      } else {
        this.prevToastID = this.$toast(message, { type: colour })
      }
    }
  }
}
</script>

<style scoped>
  >>>.v-expansion-panel-header > :not(.v-expansion-panel-header__icon) {
    flex: none;
  }
  >>>.v-text-field.v-text-field--solo .v-input__control {
    min-height: 43px;
  }
  >>>.v-badge__badge {
    height: 17px;
    min-width: 1px;
    font-weight: bold;
    padding: 2px 5px;
    margin: 0 0 1px 1px;
  }
  .v-chip--disabled {
    opacity: 1;
  }

</style>
